<template>
  <section class="hero is-fullheight" style="background-color: #167df0">
    <full-screen-loader></full-screen-loader>
    <div class="hero-body has-text-centered">
      <div style="width: 100%">
        <div class="columns">
          <div class="column"></div>
          <div class="column is-two-fifths-mobile">
            <div class="card">
              <form class="card-content" @submit.prevent="login">
                <img src="../assets/lanka_remit_logo.png" alt="logo">
                <b-field label="Username" label-position="on-border" :type="loginData.username.class"
                  :message="loginData.username.message">
                  <b-input maxlength="30" icon="account" v-model="loginData.username.value"
                    @input="loginData.username.blur()"></b-input>
                </b-field>

                <b-field label="Password" label-position="on-border" :type="loginData.password.class"
                  :message="loginData.password.message">
                  <b-input :type="passwordVisible ? '' : 'password'" icon="form-textbox-password" maxlength="30"
                    v-model="loginData.password.value" @input="loginData.password.blur()" password-reveal
                    expanded></b-input>
                </b-field>
                <div class="buttons">
                  <b-button type="is-primary" native-type="submit" @click="login()" :loading="btnLoading"
                    expanded>Login</b-button>
                </div>
              </form>
              <div style="font-size: 12px">Version <span style="font-weight: bold">{{ getAppVersionData().version
              }}</span>
                © {{ getAppVersionData().year }} LankaPay (Pvt) Ltd. All Rights Reserved</div><br>
            </div>
          </div>
          <div class="column"></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import jwtUtils from "@/helpers/jwtUtils"
import NetworkManager from "@/network"
import { FieldValidator } from "@/helpers/validators/fieldValidator"
import FullScreenLoader from "@/components/FullScreenLoader";
import { appSettings } from "@/appSettings";
import User from "@/mixins/modules/authMixins";


export default {
  name: 'Login',
  components: { FullScreenLoader },
  data() {
    return {
      btnLoading: false,
      passwordVisible: false,
      loginData: {
        username: new FieldValidator(),
        password: new FieldValidator()
      },

    }
  },

  methods: {

    getAppVersionData: function () {
      return {
        version: appSettings.version,
        year: appSettings.year
      }
    },

    validate: function () {
      let isValid = true

      let usernameErrorMessage = "Username cannot contain spaces or special characters except '_'"
      if (!this.loginData.username.validate(usernameErrorMessage)) isValid = false

      let passwordErrorMessage = "Please enter a valid password"
      if (!this.loginData.password.validate(passwordErrorMessage)) isValid = false

      return isValid
    },

    login: async function (e) {
      //e.preventDefault()
      const self = this

      if (self.validate()) {
        self.btnLoading = true

        const data = {
          username: self.loginData.username.value,
          password: self.loginData.password.value
        }

        await NetworkManager.apiRequest('api/auth', data,
          function (e) {

            let params = {
              type: '',
              message: ''
            }

            switch (e.statusCode) {
              case 200:
                jwtUtils.saveToken(e.data.token)
                if (e.data.resetPassword === 1) {
                  self.showFullScreenLoader()
                  self.navigateTo('ResetPassword')
                }
                else {
                  self.showFullScreenLoader()
                  
                  if ((User.hasPermission('auditor-dashboard-view') || User.hasPermission('auditor-report-view')) && !User.hasSuperPermission()) {
                    self.navigateTo('AuditDashboard');
                  } else {
                    self.navigateTo('Dashboard');
                  }
                }
                break
              case 400:
                params = {
                  type: 'is-warning',
                  message: 'Username or Password is invalid!'
                }
                self.$buefy.toast.open(params)
                self.loginData.username.value = ''
                self.loginData.password.value = ''
                break
                case 422:
                params = {
                  type: 'is-warning',
                  message: 'Username or Password is invalid!'
                }
                self.$buefy.toast.open(params)
                self.loginData.username.value = ''
                self.loginData.password.value = ''
                break
              case 404:
                params = {
                  type: 'is-warning',
                  message: 'Username or Password is invalid!'
                }
                self.$buefy.toast.open(params)
                self.loginData.username.value = ''
                self.loginData.password.value = ''
                break
              case 500:
                params = {
                  type: 'is-warning',
                  message: 'Username or Password is invalid!'
                }
                self.$buefy.toast.open(params)
                self.loginData.username.value = ''
                self.loginData.password.value = ''
                break
            }

            self.btnLoading = false
          }, false)
      }
    }
  },
  mounted() {
    this.stopTokenTimer()
  }
}
</script>
